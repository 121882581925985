import { currentOrder } from '@/orderStore';
import * as orderStore from '@/orderStore';
import { useStore } from '@nanostores/react';
import type { Id } from '@packages/backend/convex/_generated/dataModel';
import { useEffect } from 'react';

/**
 * A client side only, react component.
 * On page load, initialise a new order, if one doesn't yet exist.
 */
export const InitOrder = ({ restaurantId }: { restaurantId: Id<'restaurants'> }) => {
  const order = useStore(currentOrder);

  useEffect(() => {
    if (!order) {
      orderStore.initOrder(restaurantId);
    }
  }, []);

  return null;
};
